import { createClient } from '@supabase/supabase-js';


const supabaseUrl = 'https://yeqoscxmkuhqgviyfkwe.supabase.co'
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseKey);


export const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });
  
    if (error) {
      console.error('Error during sign in with Google:', error.message);
    }
};