import React, { useState } from 'react';
import { supabase, signInWithGoogle  } from '../supabaseClient';
import { Link, useNavigate } from 'react-router-dom';
import Modal from './UIComponents/Modal/Modal';
import InputField from './UIComponents/InputField/InputField';
import Button from './UIComponents/Button/Button';
import Banner from './UIComponents/Banner/Banner';

const SignInModal = ({ onClose, openSignUp, isOpen, openForgotPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      setMessage(error.message);
    } else {
      setMessage('Sign in successful.');
      onClose(); // Close the modal
      navigate('/'); // Navigate to home
    }
  };
  const handleGoogleLogin = () => {
    signInWithGoogle();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}  >
      <div className='vertical v-l'>
      
        <h4>Login</h4>
        <Button onClick={handleGoogleLogin} type='secondary' text='Continue with Google' fullWidth="true" icon="google" size="large" />

        <div className='horizontal h-s center-cross-axis'>
          <hr className='fill border-color'></hr>
          <p>or</p>
          <hr className='fill border-color'></hr>
        </div>

        <form onSubmit={handleSignIn} className='vertical v-l'>

        <div className='vertical v-m'>
          {console.log(message)}
            {message && <Banner type="warning" body={message} /> }
            <InputField
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <InputField
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button type="primary" text='Log-in' fullWidth="true" size="large"/>
          </div>
        </form>
        <div className='vertical center-cross-axis v-m'>
          <div className='horizontal center-cross-axis h-s'>
            <p>New user?</p>
            <a className='link' onClick={openSignUp}>Sign up</a>
          </div>
          <a className='link' onClick={openForgotPassword}>Forgot your password?</a>
        </div>
      </div>
    </Modal>
  );
};

export default SignInModal;
