import React, { useContext, useState, useEffect, useRef } from 'react';
import styles from './Avatar.module.css'; // Import the CSS module for styling
import { Link } from 'react-router-dom';
import { supabase } from '../../../supabaseClient';
import { AuthContext } from '../../../AuthContext';
import MenuItem from '../MenuItem/MenuItem';


const Avatar = () => {
  const { user } = useContext(AuthContext); // Get the authenticated user from AuthContext
  const [isOpen, setIsOpen] = useState(false);
  const [avatarLetter, setAvatarLetter] = useState('');
  const dropdownRef = useRef(null); // Ref for the dropdown element



  const toggleMenu = () => setIsOpen(!isOpen);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      window.location.href = '/'; // Redirect to the homepage
      // Redirect or show a message after signing out if needed
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

    // Set the avatar letter from the first letter of the user's email
    useEffect(() => {
      if (user && user.email) {
        setAvatarLetter(user.email.charAt(0).toUpperCase());
      }
    }, [user]);

      // Close the dropdown if clicked outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        // If the click is outside the dropdown, close the menu
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      // Add event listener when dropdown is open
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      // Cleanup event listener when component unmounts or dropdown is closed
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]);

  return (
    <div>
        <div className={styles.avatarContainer}>
          <div className={styles.avatarIcon} onClick={toggleMenu}>
            <h5>{avatarLetter}</h5>
          </div>
        </div>
        {isOpen && (
          <div ref={dropdownRef} className={styles.dropdown}>
              <Link to="/settings" className='no-underline'>
                  <MenuItem text="Settings" icon="settings" />
              </Link>
              
              <MenuItem text="Log out" icon="log-out" onClick={handleSignOut} />

          </div>
        )}
      </div>
  );
};

export default Avatar;
