// UpdatePassword.js
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import Button from './UIComponents/Button/Button';


const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false)

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.updateUser({
      password: password
    })

    if (error) {
      setMessage('Error: ' + error.message);
    } else {
      setMessage('Password updated successfully!');
      setUpdateSuccess(true)
    }
  };

  return (
    <div>
    {updateSuccess ? (
      <div>
        <h2>Password Updated</h2>
        <Button text="Go to the hompage"  href="/"/>
      </div>
    ):(
      <div>
      <h2>Update Password</h2>
      <form onSubmit={handleUpdatePassword}>
        <label htmlFor="password">New Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
       <button type="submit">Update Password</button>
      </form>
    </div>
    )}

      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPassword;
