import React from "react";
import Tag from "../../UIComponents/Tag/Tag";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/outline";

const Feedback = ({isCorrect, explanation, explanationImg, explanationImgSrc, explanationImgProduct, explanationImgProductLogo}) => {
    return (
        <div className="vertical v-m feedback-container fill">
            {isCorrect ? 
                <Tag style="success" type="filled" icon="white/party" text="Correct" /> : 
                <Tag style="error"  type="filled" icon="white/cross" text="Incorrect" />
            }

            <p className="fill">{explanation}</p>
        </div>
    )
}

export default Feedback