import { AuthContext } from '../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import InputField from './UIComponents/InputField/InputField';
import Button from './UIComponents/Button/Button';
import KeyValuePair from './UIComponents/KeyValuePair/KeyValuePair';
import Banner from './UIComponents/Banner/Banner';
import TopNavBar from './UIComponents/TopNavBar/TopNavBar';

const Settings = () => {
  const { user } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const [bannerType, setBannerType] = useState('default')

  // Add these new state variables
  const [savedName, setSavedName] = useState('');
  const [savedEmail, setSavedEmail] = useState('');

  useEffect(() => {
    if (user) {
      const userEmail = user.email || '';
      const userName = user.user_metadata?.name || '';
      setEmail(userEmail);
      setName(userName);
      setSavedEmail(userEmail);
      setSavedName(userName);
    }
  }, [user]);

  const handleUpdateEmail = async () => {
    const { user, error } = await supabase.auth.updateUser({
      email: email
    });
    if (error) {
      setBannerType('error')
      setMessage(`Error updating email: ${error.message}`);
    } else {
      setBannerType('default')
      setMessage('An email has been sent to both emails. Please check your inbox and confirm.');
    }
  };

  const handleUpdateName = async () => {
    const { user, error } = await supabase.auth.updateUser({
      data: {
        name:name
      }
    });
    if (error) {
      setBannerType('error')
      setMessage(`Error updating name: ${error.message}`);
    } else {
      setBannerType('success')
      setMessage('Name updated.');
    }
  };

  const handleUpdatePassword = async () => {
    const { error } = await supabase.auth.updateUser({
      password: password,
    });

    if (error) {
      setBannerType('warning')
      setMessage(`Error updating password: ${error.message}`);
      console.error('Error updating password:', error);
    } else {
      setBannerType('success')
      setMessage('Password updated successfully!');
      setPassword(''); // Clear password field
      console.log('Password updated');
    }
  };
  const handleNameEdit = () => {
    setIsEditingName(true);
  };
  const handleNameCancel = () => {
    setIsEditingName(false);
    setName(savedName); // Revert to saved name
  };

  const handleNameSave = () => {
    setIsEditingName(false);
    handleUpdateName();
    setSavedName(name); // Update saved name
  };

  const handleEmailEdit = () => {
    setIsEditingEmail(true);
  };

  const handleEmailSave = () => {
    setIsEditingEmail(false);
    handleUpdateEmail();
    setSavedEmail(email); // Update saved email
  };
  const handleEmailCancel = () => {
    setIsEditingEmail(false);
    setEmail(savedEmail); // Revert to saved email
  }

  const handlePasswordEdit = () => {
    setIsChangingPassword(true);
  };

  const handlePasswordSave = () => {
    setIsChangingPassword(false);
    handleUpdatePassword();
  };

  if (!user) {
    return <div>Loading...</div>; // Handle the case when user data is not yet available
  }

  return (
    <div>
      <TopNavBar scrollEffect={false} />
      <div className='vertical v-l less-constrained-width top-section-padding'>
        <h1>Settings</h1>
      
        {message && <Banner body={message} type={bannerType}/>}

        {isEditingName ? (
          <div className='vertical v-s'>
            <InputField
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)} 
              label='Name'
            />
            <div className='flex horizontal h-s'>
              <Button text='Save' onClick={handleNameSave} />
              <Button text='Cancel' type='secondary' onClick={handleNameCancel} />
            </div>
          </div>
        ) : (
          <KeyValuePair keyName="Name" value={name} editable={true} handleEdit={handleNameEdit} stroke={true}/>
        )}

        {isEditingEmail ? (
          <div className='vertical v-s'>
            <InputField
              type="email"
              value={email}
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className='flex horizontal h-s'>
              <Button text='Save' onClick={handleEmailSave} />
              <Button text='Cancel' type='secondary' onClick={handleEmailCancel} />
            </div>
          </div>
        ) : (
          <KeyValuePair keyName="Email" value={email} editable={true} handleEdit={handleEmailEdit} stroke={true}/>
        )}
        <div>
        {isChangingPassword ? (
          <div className='vertical v-s'>
            <InputField
              type="password"
              placeholder="New Password"
              value={password}
              label="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button onClick={handlePasswordSave} text='Update Password' />
          </div>
        ) : (
          <div className='vertical v-s'>
            <h6>Password</h6>
            <Button onClick={handlePasswordEdit} text='Change password' />
          </div>
        )}
      </div>
      
      </div>
    </div>
  );
};

export default Settings;
