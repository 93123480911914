import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import HomePage from './HomePage';
import Quiz from './Quiz';
import TypoQuizData from './quizData/typoQuizData';
import ColourQuizData from './quizData/colourQuizData';
import IconQuizData from './quizData/iconQuizData';
import EightPtSystemQuizData from './quizData/eightptsystemQuizData';
import ProgressiveDiscQuizData from './quizData/progressiveDiscQuizData';
import gestaltQuizData from './quizData/gestaltQuizData';
import visualHierarchyQuizData from './quizData/visualhierachyQuizData';
import './style.css';
import Settings from './components/Settings';
import RequestResetPassword from './components/RequestResetPassword';
import ResetPassword from './components/ResetPassword';
import Onboarding from './components/Onboarding';
import Privacy from './Privacy';
import { useEffect, useState } from 'react';
import { supabase } from './supabaseClient';
import ReactGA from 'react-ga4'; // Import GA4

ReactGA.initialize("G-YGR17TRXV6"); // Replace with your Measurement ID


function App() {



  // Filter the intro objects from each quiz data file
  const filterIntroObjects = (quizData) => {
    return quizData.filter(item => item.type === 'intro');
  };

  const introObjects = [
    ...filterIntroObjects(visualHierarchyQuizData),
    ...filterIntroObjects(TypoQuizData),
    ...filterIntroObjects(ColourQuizData),
    ...filterIntroObjects(IconQuizData),
    ...filterIntroObjects(EightPtSystemQuizData),
    ...filterIntroObjects(ProgressiveDiscQuizData),
    ...filterIntroObjects(gestaltQuizData),
  ];

  const GAListener = () => {
    const location = useLocation();
  
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);
  
    return null;
  };


  return (
    <Router>
      <GAListener />
      <Routes>
        <Route exact path='/' element={<HomePage introData={introObjects} />}   />
        <Route path='/typogrpahy-lesson' element={<Quiz quizData={TypoQuizData} />}  />
        <Route path='/colour-lesson' element={<Quiz quizData={ColourQuizData} />}  />
        <Route path='/icon-lesson' element={<Quiz quizData={IconQuizData} /> }  />
        <Route path='/eight-pt-system' element={<Quiz quizData={EightPtSystemQuizData} />}  />
        <Route path='/progressive-disc' element={<Quiz quizData={ProgressiveDiscQuizData} />}  />
        <Route path='/gestalt' element={<Quiz quizData={gestaltQuizData} />}  />
        <Route path='/visual-hierarchy' element={<Quiz quizData={visualHierarchyQuizData} />}  />
        <Route path='/settings' element={<Settings />}  />
        <Route path="/update-password" element={<ResetPassword />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path='/privacy' element={<Privacy />} />
      </Routes>
    </Router>
  )

}
export default App;