import React from "react";
import styles from './Option.module.css';

const Option = ({ optionIndex, handleOptionSelect, showFeedback, index, option, correctAnswer, selectedOptions, isCorrect }) => {
    
    const handleClick = () => {
        if (!showFeedback[index]) {
            handleOptionSelect(option, correctAnswer, index);
        }
    };

    // Determine the CSS class based on the feedback and selection
    const getOptionClass = () => {
        if (showFeedback[index]) {
            if (option === correctAnswer) {
                return styles.correct;
            } else if (option === selectedOptions[index] && !isCorrect[index]) {
                return styles.incorrect;
            } else {
                return styles.feedback;
            }
        }
        return styles.option;
    };

    return (
        <li 
            key={optionIndex} 
            onClick={handleClick} 
            className={`${styles.option} ${getOptionClass()}`}
        >
            {option}
        </li>
    );
};

export default Option;
