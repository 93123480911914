import React from "react";
import ProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";
import Logo from "./Logo";

const Header = ({currentQuestionIndex, quizDataLength}) => {
    return (
        <header className="quiz-header" >
            <Logo />
            <ProgressBar currentQuestionIndex={currentQuestionIndex} quizDataLength={quizDataLength} />
            <Link className="cross-icon-container" to="/">
                <img src={require(`../images/icons/cross.svg`).default}/>
            </Link>
        </header>
    )
}

export default Header