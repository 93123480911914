import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from './Button.module.css'; // Importing CSS Module

const Button = ({type = 'primary', state = 'default', icon = null, text, href, fullWidth=false, onClick, topMargin, size}) => {
    const navigate = useNavigate();
    const buttonStyles = `${styles.button} ${styles[size]} ${styles[type]} ${styles[state]} ${fullWidth ? styles.fullWidth : ''} ${topMargin && styles.topMargin}`

    const handleClick = () => {
        if(onClick){
            onClick();
        }
        if (href) {
            navigate(href);
        }
    };
    return (
        <button className={buttonStyles} onClick={handleClick} disabled={state === 'disabled'} >
            {text}
            {icon && <img src={require(`../../../images/icons/${icon}.svg`)} alt="" className={styles.icon} />}
        </button>
    )
}
Button.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary']),
    state: PropTypes.oneOf(['default', 'hover', 'active', 'disabled']),
    icon: PropTypes.string,
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func ,
    size: PropTypes.string,
};


export default Button