import React from "react";


const Intro = ({item}) => {
    return(
        <div className="vertical v-m constrained-width intro-container">
            <div className="vertical background-surface-container-low border-variant center-main-axis center-cross-axis lesson-card-icon-container-lg">
                {item.icon && <img src={require(`../../../images/icons/${item.icon}`)} />}
            </div>
        <h3 className="on-surface">{item.title}</h3>
        <ul className="horizontal h-m">
            <li className="horizontal center-cross-axis h-xs">
                <img className="size-m" src={require(`../../../images/icons/lucide_clock.svg`).default} />
                <p className="on-surface">{item.duration + " min"}</p>
            </li>
            <li className="horizontal center-cross-axis h-xs">
                <img className="size-m" src={require(`../../../images/icons/lucide_dumbbell.svg`).default} />
                <p className="on-surface">{item.level}</p>
            </li>
        </ul>
        <p className="on-surface-variant">{item.description}</p>
      
    </div>
    )
}

export default Intro;