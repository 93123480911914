import React from "react";
import Button from "../Button/Button";
import styles from "./KeyValuePair.module.css"

const KeyValuePair = ({keyName, value, editable, handleEdit, stroke}) => {
    return(
        <div className={`vertical v-padding-m ${stroke ? styles.stroke : ''}`}>
            <div className="horizontal space-between center-cross-axis">
                <h6>{keyName}</h6>
                {editable && <Button type="tertiary" text="Edit" onClick={handleEdit}/> }
            </div>
            <p>{value}</p>
        </div>
    )
}
export default KeyValuePair;