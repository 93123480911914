import React from 'react';
import styles from './Modal.module.css';
import {ReactComponent as CrossIcon} from '../../../images/icons/cross.svg'

const Modal = ({ isOpen, onClose, children }) => {
   
    if (!isOpen) return null;
    const handleOverlayClick = (e) => {
        // Check if the click target is the overlay and not the content
        if (e.target === e.currentTarget) {
            onClose(); // Close the modal
        }
    };


    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={`${styles.modalContent} vertical v-l h-padding-l v-padding-l`} >
                <button onClick={onClose} className={styles.modalCloseIcon}>
                    <CrossIcon />
                </button>
                {children}
            </div>
        </div>
    );
}

export default Modal;
