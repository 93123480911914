import React, { useState,useEffect, useRef, useContext } from 'react';
import Info from './components/QuizComponents/Info/Info';
import Question from './components/QuizComponents/Question/Question';
import QuestionImage from './components/QuizComponents/QuestionImage/QuestionImage';
import Summary from './components/Summary';
import Header from './components/Header';
import correctSound from './audio/correct-sound.mp3';
import incorrectSound from './audio/incorrect-sound.mp3';
import clickSound from './audio/click-sound.mp3';
import Intro from './components/QuizComponents/Intro/Intro';
import { Link } from 'react-router-dom';
import amplitude from 'amplitude-js';
import QuestionMatchingPairs from './components/QuizComponents/MatchingPairs/MatchingPairs';
import { setLessonStatus } from './services/lessonStatusService';
import Button from './components/UIComponents/Button/Button';
import { supabase } from './supabaseClient';
import { AuthContext } from './AuthContext';



const Quiz = ({ quizData}) => {
  const [selectedOptions, setSelectedOptions] = useState(Array(quizData.length).fill(null));
  const [showFeedback, setShowFeedback] = useState(Array(quizData.length).fill(false));
  const [isCorrect, setIsCorrect] = useState(Array(quizData.length).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(quizData.length).fill(null));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const ref = useRef(null);
  const [lessonName, setLessonName] = useState('');
  const [lessonId, setLessonId] = useState('');
  const [buttonState, setButtonState] = useState('default');
  const { user } = useContext(AuthContext);
  
  const [status, setStatus] = useState('non-started');

  const insights = quizData
  .filter(item => item.type === 'info' && item.insight) // Filter items of type 'info' with non-empty insights
  .map(item => item.insight); // Extract the 'insight' field

    // Scroll to the top on page load
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

  useEffect(() => {
    const introItem = quizData.find(item => item.type === "intro");
    if (introItem) {
      setLessonName(introItem.title);
      setLessonId(introItem.id)
    }
  }, [quizData]);

  // Disable button if it's a question type
  useEffect(() => {
    if (quizData[currentQuestionIndex].type === 'question' || quizData[currentQuestionIndex].type === 'question-image' || quizData[currentQuestionIndex].type === 'question-matching-pairs') {
      setButtonState('disabled');
    } else {
      setButtonState('default');
    }
  }, [currentQuestionIndex, quizData]);


  useEffect(() => {
    const initialCorrectAnswers = quizData.map(item => 
      item.type === 'question' || item.type === 'question-image' ? item.correctAnswer : null
    );
    setCorrectAnswers(initialCorrectAnswers);
  }, [quizData]);

  const amplitudeHandleStart = () => {


    handleNextButtonClick();
    
    // Log the event to Amplitude only in production
    if (process.env.REACT_APP_ENV === 'production') {
      
      console.log("amplitude-triggered")
      amplitude.getInstance().logEvent('start_lesson', { lessonName });
    }
  };

  const amplitudeHandleFinish = async () => {


        await setLessonStatus(user.id, lessonId, 'completed');
        setStatus('completed');

        // Log the event to Amplitude only in production
        if (process.env.REACT_APP_ENV === 'production') {
          amplitude.getInstance().logEvent('finish_lesson', { lessonName });
        }
   
  }

  const handleAllPairsMatched = (questionIndex) => {
    setShowFeedback(prevState => {
      const updatedShowFeedback = [...prevState];
      updatedShowFeedback[questionIndex] = true;
      return updatedShowFeedback;
    });
    setButtonState('default');
  };

  const scrollToFeedback = () => {
   
    // Select all feedback containers
    const feedbackContainers = document.querySelectorAll('.feedback-container');
  
    // Check if there are any feedback containers
    if (feedbackContainers.length > 0) {
      // Get the last feedback container
      const lastFeedbackContainer = feedbackContainers[feedbackContainers.length - 1];
      
      // Function to scroll to the last feedback container
      const scrollToLastFeedback = () => {
        // Get the position of the last feedback container relative to the document
        const feedbackPosition = lastFeedbackContainer.getBoundingClientRect().top + window.scrollY;
  
        // Calculate the position to scroll to, adjusting for a 64px offset
        const scrollToPosition = feedbackPosition - 64;
  
        // Scroll to the calculated position with smooth behavior
        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth'
        });
      };
  
      // Wait for all images to load before scrolling
      const images = document.querySelectorAll('img');
      const imagePromises = Array.from(images).map(img => {
        if (img.complete) {
          return Promise.resolve();
        } else {
          return new Promise((resolve) => {
            img.onload = img.onerror = resolve;
          });
        }
      });
  
      // Scroll after all images have loaded
      Promise.all(imagePromises).then(scrollToLastFeedback);
    }
  };
  
 
  


  const handleOptionSelect = (selectedAnswer, correctAnswer, questionIndex) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[questionIndex] = selectedAnswer;
    setSelectedOptions(updatedSelectedOptions);

    // Update the correctAnswers array at the right index
    const updatedCorrectAnswers = [...correctAnswers];
    updatedCorrectAnswers[questionIndex] = correctAnswer;
    setCorrectAnswers(updatedCorrectAnswers);

    setShowFeedback(prevState => {
      const updatedShowFeedback = [...prevState];
      updatedShowFeedback[questionIndex] = true;
      return updatedShowFeedback;
    });
    
    setTimeout(scrollToFeedback, 300);
    


    setIsCorrect(prevState => {
      const updatedIsCorrect = [...prevState];
      updatedIsCorrect[questionIndex] = selectedAnswer === correctAnswer;
      return updatedIsCorrect;
    });

     // Enable the button after selecting an option
     setButtonState('default');


    // Play positive feedback audio if the answer is correct
    if (selectedAnswer === correctAnswer) {
      const correctAudio = new Audio(correctSound);
      correctAudio.play();
    }else{
      const incorrectAudio = new Audio(incorrectSound);
      incorrectAudio.play();
    }

    
  };

  const handleCheckAnswer = () => {
    setShowFeedback(prevState => {
      const updatedShowFeedback = [...prevState];
      updatedShowFeedback[currentQuestionIndex] = true;
      return updatedShowFeedback;
    });

    setIsCorrect(prevState => {
      const updatedIsCorrect = [...prevState];
      updatedIsCorrect[currentQuestionIndex] = selectedOptions[currentQuestionIndex] === correctAnswers[currentQuestionIndex];
      return updatedIsCorrect;
    });
    
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    
  };

  const scrollToNextQuestion = () => {

    const nextQuestionIndex = currentQuestionIndex + 1;
    const nextQuestionElement = document.getElementById(nextQuestionIndex.toString());
  
    if (nextQuestionElement) {
      // Function to scroll to the next question element
      const scrollToNext = () => {
        const topMargin = nextQuestionElement.offsetTop - 80; // Adjust for margin
        
        window.scrollTo({
          top: topMargin,
          behavior: 'smooth'
        });
      };
  
      // Wait for all images to load before scrolling
      const images = nextQuestionElement.querySelectorAll('img');
      const imagePromises = Array.from(images).map(img => {
        if (img.complete) {
          return Promise.resolve();
        } else {
          return new Promise((resolve) => {
            img.onload = img.onerror = resolve;
          });
        }
      });
  
      // Scroll after all images have loaded
      Promise.all(imagePromises).then(scrollToNext);
    }
    
  };

const handleNextButtonClick = async () => {
  
    if(currentQuestionIndex === quizData.length-2){
      console.log("last question")
      await amplitudeHandleFinish();
    }
    handleNextQuestion();
    setTimeout(scrollToNextQuestion, 100); // Adjust the delay as needed
    const clickAudio = new Audio(clickSound);
    clickAudio.play();

  }
 

  const handleAllCorrect = () => {
    setButtonState('default');
  };



  return (
    <div className='quiz-content  constrained-width'>
      <Header currentQuestionIndex={currentQuestionIndex} quizDataLength={quizData.length} />

    

      <div className='vertical v-huge  center-cross-axis quiz-content-inner'>
      {quizData.map((item, index) => (
        index <= currentQuestionIndex && (
          
          <div key={index} ref={ref} id={index} className='quiz-element'>
            {item.type==="intro" && (
              <Intro item={item} />
            )}
           

            {item.type === 'question' && (
              <Question 
                item={item}
                index={index}
                handleOptionSelect={handleOptionSelect}
                showFeedback={showFeedback}
                isCorrect={isCorrect}
                correctAnswers={correctAnswers}
                selectedOptions={selectedOptions}
              />
            )}

            {item.type === 'question-matching-pairs' && (
              <QuestionMatchingPairs
              pairs={item.pairs}
              onAllPairsMatched={() => handleAllPairsMatched(index)}
              questionIndex={index}
              showFeedback={showFeedback}
              scrollToFeedback={scrollToFeedback}
              explanation={item.explanation}
              />
            )}


            {item.type === 'question-image' && (
              <QuestionImage 
                item={item}
                index={index}
                handleOptionSelect={handleOptionSelect}
                showFeedback={showFeedback}
                isCorrect={isCorrect}
                correctAnswers={correctAnswers}
                selectedOptions={selectedOptions}
               
              />
            )}

            {item.type === 'info' && (
               <Info item={item} key={index}/>
              
            )}

            {item.type === 'summary' && (
              <Summary item={item} lessonName={lessonName} key={index} insights={insights}/>
             
           )}

          </div>
        
        )
      ))}
      </div>
      <div className='quiz-button-container'>
        
        { currentQuestionIndex === 0 ? (
            <Button onClick={handleNextButtonClick} text='Start' topMargin={true} size="large" icon="white/arrow"/>
          ) : (
          currentQuestionIndex+1 < quizData.length || showFeedback[currentQuestionIndex] ? (   
            <Button onClick={handleNextButtonClick} text='Continue' state={buttonState} topMargin={true} size="large" icon="white/arrow"/>
          ) : (      
            <Button href="/" text='Finish' state={buttonState} size="large" />
          )
        )}
      </div>


    </div>
  );
};

export default Quiz;
