import React, { useState } from 'react';
import styles from './InputField.module.css';
import {ReactComponent as EyeOnIcon} from '../../../images/icons/eye.svg'
import {ReactComponent as EyeOffIcon} from '../../../images/icons/eye-off.svg'


const InputField = ({ type, placeholder, icon, errorMessage, value, onChange, label }) => {
  const [inputType, setInputType] = useState(type); // Use type passed as prop
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);

  const togglePasswordVisibility = () => {
    setInputType(inputType === 'password' ? 'text' : 'password'); // Toggle between 'password' and 'text'
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (errorMessage && value.length === 0) {
      setIsError(true);
    }
  };

  return (
    <div className='vertical'>
      <h6>{label}</h6>
      <div className={`${styles.inputField} vertical ${isFocused ? styles.focused : ''} ${isError ? styles.error : ''}`}>
        <input
          type={inputType}
          value={value}
          placeholder={placeholder}
          onChange={onChange} // Use the onChange prop passed from the parent
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
      
        />
        {type === 'password' && (
          <span className={styles.toggleIcon} onClick={togglePasswordVisibility}>
            {inputType === 'password' ? <EyeOnIcon /> : <EyeOffIcon />}
          </span>
        )}
        {icon && <span className="input-icon">{icon}</span>}
        {isError && errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
      </div>
    </div>
  );
};

export default InputField;
