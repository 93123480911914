import { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { supabase } from '../supabaseClient';

const Onboarding = () => {
  const { user } = useContext(AuthContext); // Access user and setUser from context
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [designExperience, setDesignExperience] = useState('');
  const [message, setMessage] = useState('');


 
  // Handle name submission
  const handleNameSubmit = (event) => {
    event.preventDefault();
    setStep(2);
  };

  // Handle design experience update
  const handleDesignExperienceSubmit = async (event) => {
    event.preventDefault();
    try {

      const { error } = await supabase.auth.updateUser({
        data: {
          design_experience: designExperience,
          name:name
        },
      });
      if (error) throw error;
      setMessage('Registration successful!');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      

      {step === 1 && (
        <form onSubmit={handleNameSubmit}>
          <h2>Step 2: Your Name</h2>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <button type="submit">Next</button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleDesignExperienceSubmit}>
          <h2>Step 3: Design Experience</h2>
          <select
            value={designExperience}
            onChange={(e) => setDesignExperience(e.target.value)}
          >
            <option value="">Select your design experience (Optional)</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
          <button type="submit">Finish</button>
        </form>
      )}

      {message && <p>{message}</p>}
    </div>
  );
};

export default Onboarding;
