const Privacy = () => {
    return (


    <div class="constrained-width vertical v-xl">
        <h1>Privacy Policy</h1>
        <div>
            <p><strong>Effective Date:</strong> 16.10.2024</p>
            <p><strong>Last Updated:</strong> 16.10.2024</p>

            <p>
                Betterdesigner.me ("we", "our", "us") is committed to protecting your personal data and respecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website [website URL] and when you register for an account with us. It also explains your rights in relation to your personal data.
            </p>
            <p>Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
        </div>
        <div>
            <h2>1. Information We Collect</h2>
            <p>When you visit and use our website, or when you register for an account, we may collect the following categories of personal data:</p>
            <ul>
                <li><strong>Personal identification data</strong> (name, email address)</li>
                <li><strong>Login details</strong> (username, password)</li>
            </ul>
            <p>We do not collect any special categories of personal data (such as racial or ethnic origin, political opinions, religious beliefs, health data, or data concerning sexual orientation) unless explicitly provided by you.</p>

        </div>
        
        <div>
        <h2>2. How We Use Your Information</h2>
        <p>We process your personal data for the following purposes:</p>
        <ul>
            <li>To provide you with access to our website and services.</li>
            <li>To manage your account and user registration.</li>
            <li>To process payments and transactions (where applicable).</li>
            <li>To improve website functionality and the user experience.</li>
            <li>To communicate with you regarding updates, security alerts, and support.</li>
            <li>To comply with legal obligations (e.g., verifying your identity to prevent fraud).</li>
            <li>To analyze website traffic and usage trends.</li>
        </ul>

        </div>
        
        <div>
        <h2>3. Legal Basis for Processing</h2>
        <p>We process your personal data based on one or more of the following legal grounds under the General Data Protection Regulation (GDPR):</p>
        <ul>
            <li><strong>Consent</strong>: When you provide your explicit consent for certain types of processing (e.g., subscribing to newsletters).</li>
            <li><strong>Contract</strong>: To fulfill our contractual obligations with you (e.g., providing services you requested).</li>
            <li><strong>Legal Obligation</strong>: To comply with legal or regulatory obligations (e.g., tax reporting, anti-fraud measures).</li>
            <li><strong>Legitimate Interest</strong>: When it is necessary for the legitimate interests of our business, provided that your rights and freedoms are not overridden (e.g., improving our services, analyzing website usage).</li>
        </ul>
        </div>
        
        <div>
        <h2>4. Sharing Your Information</h2>
        <p>We do not sell, trade, or otherwise transfer your personal data to third parties without your consent, except in the following situations:</p>
        <ul>
            <li><strong>Service providers</strong>: We may share your data with third-party service providers who help us run our website and services (e.g., hosting providers, payment processors). These providers are bound by confidentiality agreements and are only permitted to use your data for the purposes we specify.</li>
            <li><strong>Legal obligations</strong>: We may disclose your data if required by law or if we believe such action is necessary to comply with legal obligations, protect our rights, or ensure the safety of others.</li>
            <li><strong>Business transfers</strong>: In the event of a merger, acquisition, or asset sale, your data may be transferred as part of the business transaction, subject to confidentiality agreements.</li>
        </ul>
        </div>


        <div>
        <h2>5. Your Rights Under GDPR</h2>
        <p>As a data subject in the EU, you have the following rights regarding your personal data:</p>
        <ul>
            <li><strong>Right of access</strong>: You have the right to request a copy of the personal data we hold about you.</li>
            <li><strong>Right to rectification</strong>: You have the right to request corrections to any inaccurate or incomplete data.</li>
            <li><strong>Right to erasure (right to be forgotten)</strong>: You can request the deletion of your personal data, subject to certain legal obligations.</li>
            <li><strong>Right to restrict processing</strong>: You can request the restriction of processing your data under certain conditions.</li>
            <li><strong>Right to data portability</strong>: You have the right to receive your data in a structured, commonly used, and machine-readable format and transfer it to another controller.</li>
            <li><strong>Right to object</strong>: You can object to the processing of your personal data based on legitimate interests or direct marketing.</li>
            <li><strong>Right to withdraw consent</strong>: If we are processing your data based on consent, you can withdraw that consent at any time.</li>
            <li><strong>Right to lodge a complaint</strong>: You can file a complaint with your local data protection authority if you believe we are not complying with data protection laws.</li>
        </ul>
        <p>To exercise these rights, please contact us at maksimskaracuns@gmail.com</p>
        </div>

        <div>
        <h2>6. Data Retention</h2>
        <p>We will retain your personal data only for as long as necessary to fulfill the purposes we collected it for, including for legal, accounting, or reporting purposes. When we no longer need your data, we will securely delete or anonymize it.</p>
        </div>

        <div>
        <h2>7. Data Security</h2>
        <p>We take appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, destruction, or alteration. These measures include encryption, firewalls, secure servers, and access controls.</p>
        <p>However, no method of transmission over the internet or method of electronic storage is completely secure. While we strive to protect your data, we cannot guarantee its absolute security.</p>
        </div>

        <div>
        <h2>8. International Data Transfers</h2>
        <p>Your personal data may be transferred to and processed in countries outside the European Economic Area (EEA). If such transfers occur, we will ensure that your data is adequately protected by using safeguards such as standard contractual clauses or relying on adequacy decisions by the European Commission.</p>
        </div>

     

        <div>
        <h2>9. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date". We encourage you to review this policy periodically to stay informed about how we protect your data.</p>
        </div>

        <div>
        <h2>11. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
        <ul>
            <li><strong>Email:</strong> maksimskaracuns@gmail.com</li>
            <li><strong>Postal Address:</strong> Sardenya 447, Barcelona, 08025, Spain</li>
            <li><strong>Phone Number:</strong> +34603827113</li>
        </ul>
        </div>

        <p>By using our website and services, you acknowledge that you have read and understood this Privacy Policy.</p>
    </div>


    )
}

export default Privacy