import React from "react";
import styles from "./Image.module.css"

const Image = ({image, imageProductSrc, imageProductLogo, imageProduct}) => {

    return (
            <div className="vertical v-s fill relative imageWrapper border-radius">
                <img src={require(`../../../images/${image}`)}  className="questionImage"/>
              {imageProduct && (
                <a className={`horizontal h-s ${styles.reference}  border-variant  border-radius center-cross-axis`} href={imageProductSrc}  target="_blank" >
                    <img className={`${styles.productImage} border-radius`} src={require(`../../../images/${imageProductLogo}`)} />
                    <p className={`small-body ${styles.imageProductText}`}>{imageProduct}</p>
                </a>
              )}
            </div>
           
    )
}

export default Image