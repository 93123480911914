const colourGroupsTable = (
    <div className="vertical v-m">
        <p>Generally, UI colours can be divided into three most important groups:</p>

        <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td><strong>Accent colours</strong></td>
              <td>These are used to highlight or draw attention to important elements on the screen.</td>
            </tr>
            <tr>
              <td><strong>Neutral colours</strong></td>
              <td>These are more subdued and are often used for backgrounds, borders, text, and icons.</td>
            </tr>
            <tr>
              <td><strong>Semantic colours</strong></td>
              <td>These are used to convey meaning or status, such as success, warning, error, and information messages.</td>
            </tr>
  
          </tbody>
        </table>
      </div>
    
    </div>
   
  
)
const accentGroupsTable = (
    <div className="vertical v-m">
        <p>Accent colours can be categorised  further into two sub-groups:</p>

        <div className='table-container'>
        <table>
          <tbody>
            <tr>
              <td><strong>Primary colours</strong></td>
              <td>These are utilised to direct users’ attention to the most critical elements in the UI, such as primary buttons.</td>
            </tr>
            <tr>
              <td><strong>Secondary colours</strong></td>
              <td>These are employed to highlight secondary elements like secondary buttons and selection controls.</td>
            </tr>
          </tbody>
        </table>
      </div>
    
    </div>
   
  
)


const colourQuizData = [

    {
        type: 'intro',
        id: 'colour-lesson',
        image: 'colour-cover.png',
        title: 'Understanding UI Colour Palette',
        duration: '8',
        icon: 'paint-bucket.svg',
        level: 'Medium',
        description: "In this lesson, you'll discover the different color groups in the UI palette and how each is used effectively in design.",
        keyInsights: ['Different colour groups.', 'Why we need primary and secondary colours.', '"60-30-10" rule.', 'Why you need multiple shades of each colour.'],
    },

    {
        type: 'info',
        title: "Introduction to UI Colour Groups",
        image: '',
        htmlContent: colourGroupsTable,
        text: '',
    },
    {
        type: 'info',
        variant: 'tip' ,
        title: "Accent Colours",
        image: 'colour/google-maps.png',
        imageProduct: 'Google Maps',
        imageProductSrc: 'https://www.google.com/maps',
        imageProductLogo: 'colour/google-maps-logo.png',
        text: 'Accent colors are used to highlight key elements on the screen, directing the user’s attention and making important information more prominent.',
        insight: "Accent colours are used to emphasise and direct attention toward key elements on the screen"
    },
    {
        type: 'question',
        question: 'What is the primary purpose of accent colours in UI design?',
        image: '',
        options: ['To serve as the main background colour', 'To direct focus towards important elements', 'These convey meaning or status'],
        correctAnswer: 'To direct focus towards important elements',
        explanation: "Accent colors are employed to emphasise key elements on the screen, helping to guide the user’s focus and make important information stand out. They are strategically used to create visual contrast, enhancing the overall user experience by making critical actions or details more noticeable."
    },
    {
        type: 'question',
        question: 'What colour acts as the accent in this example?',
        image: 'colour/strava.png',
        imageDesktopLarge: true,
        imageProduct: 'Strava',
        imageProductSrc: 'https://www.strava.com/',
        imageProductLogo: 'colour/strava-logo.png',
        options: ['Beige', 'Black', 'Orange', 'Green'],
        correctAnswer: 'Orange',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: 'https://www.strava.com/',
        explanation: "Orange is an accent color of Strava, used to direct attention to crucial elements on the screen, such as the route and the 'Save Route' action."
    },
    {
        type: 'info',
        variant: 'tip' ,
        title: "Accent or Brand Colours",
        image: 'colour/brand-colour-brands.png',
        text: 'Accent colours are often referred to as brand colours. This is the most defining colour group, as users will often associate your product with that colour. Think of red for CocaCola, blue for Facebook, green for Starbucks etc.',
    },
    {
        type: 'info',
        variant: 'tip' ,
        title: "Neutral Colours",
        image: 'colour/brand-colour-brands.png',
        image: 'colour/neutral-multitude-shades.png',
        text: 'Neutral colors, known for their understated and muted tones, are commonly used for elements such as backgrounds, borders, text, and icons. These colors provide a balanced foundation for the design, ensuring that more vibrant accent colors can shine without distraction.',
        insight: "Neutral colours are more subdued and are often used for backgrounds, borders, text, and icons."
    },



    {
        type: 'question',
        question: 'What is NOT the common use case for neutral colour group?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['Background', 'Borders', 'Icons', 'Buttons'],
        correctAnswer: 'Buttons',
        explanationImg: 'colour/neutral-colours-calendar.png',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "Normally, accent colours are used for button elements, while neutral colours are more subtle and used for text, backgrounds, borders and icons."
    },

    {
        type: 'question',
        question: 'A red colour error message is an example of which colour group?',
        image: 'colour/airbnb-error.png',
        imageProduct: 'Airbnb',
        imageProductSrc: 'https://www.airbnb.com/',
        imageProductLogo: 'colour/airbnb-logo.png',
        imageDesktopLarge: true,
        options: ['Accent', 'Neutral', 'Structure', 'Semantic'],
        correctAnswer: 'Semantic',
        explanationImg: '',
        explanationImgProduct: '',
        explanationImgProductLogo: '',
        explanationImgSrc: '',
        explanation: "Red colour is often used for warnings and is an example of a semantic colour group."
    },
    {
        type: 'question',
        question: 'What is the primary purpose of the semantic colours in UI design?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['To direct focus towards important elements', 'For visual decoration', 'To convey meaning or status'],
        correctAnswer: 'To convey meaning or status',
        explanationImg: 'colour/booking-error.png',
        explanationImgProduct: 'Booking',
        explanationImgProductLogo: 'colour/booking-logo.png',
        explanationImgSrc: 'https://www.booking.com/',
        explanation: "Semantic colours are used to convey meaning or status, such as success, warning, error, and information messages."
    },
    {
        type: 'info',
        variant: 'tip' ,
        title: "Semantic Colours",
        image: 'colour/semantic-colours.png',
        text: 'Semantic colors in UI design are colors that convey specific meanings or functions, such as red for errors or green for success. These colors help users quickly understand the purpose or status of elements based on familiar associations and often applied to buttons, alerts etc.',
        insight: "Semantic colours are used to convey meaning or status, such as success, warning, error, and information messages."
    },
    {
        type: 'question',
        question: 'Which semantic colour would work best to display success messages?',
        image: '',
        imageProduct: '',
        imageProductSrc: '',
        imageProductLogo: '',
        options: ['Blue', 'Green', 'Yellow', 'Black'],
        correctAnswer: 'Green',
        explanationImg: 'colour/duolingo-success.png',
        explanationImgProduct: 'Duolingo',
        explanationImgProductLogo: 'colour/duolingo-logo.png',
        explanationImgSrc: 'https://www.duolingo.com/',
        explanation: "Green is often the best choice as it's commonly associated with positive messages. "
    },
    {
        type: 'question-image',
        question: 'Which option would work best to display a warning message?',
        options: ['colour/warning-option-1.png','colour/warning-option-2.png'],
        correctAnswer: 'colour/warning-option-1.png',
        explanation: "Yellow is often used to catch the user's attention and indicate that there is something they need to be cautious about or pay attention to. It's a colour that can signify warning without being as intense or alarming as red."
    },
   
    {
        type: 'info',
        variant: 'fun-fact' ,
        image: 'colour/colour-white-symbolism.png',
        text: "Be aware that colors can have different meanings and symbolism in different cultures. For example, white is associated with purity in Western cultures but symbolises mourning in many Asian cultures.",
      },
      

    {
        type: 'summary',
    
    }
]

export default colourQuizData