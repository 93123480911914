const gestaltQuizData = [
    {
        type: 'intro',
        image: 'progressive-disc-cover.png',
        id: 'gestalt',
        title: 'Gestalt Principles',
        duration: '3',
        keyInsights: ['', '',''],
        icon: 'grip.svg',
        level: 'Beginner',
        description: 'Understand what Gestalt principles are and how they can be used to effectively present information on the screen.',
    },
    {
        type: 'info',
        image: 'gestalt/gestalt-principles-overview.png',
        title: "Introduction",
        text: 'Gestalt visual principles are a set of rules that govern how the human brain perceives visual information i.e. chunking. In this lesson we going to focus on three main Gestalt principles: Proximity, Similarity and Common region.',
        insight: 'Chunking reduces cognitive load, helping users make faster decisions.',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'info',
        image: 'gestalt/proximity.png',
        title: "Proximity Principle",
        text: 'Objects that are close together are perceived as belonging to the same group.',
        insight: 'Position objects close to each other to create visual groups, following the proximity principle.',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'info',
        image: 'gestalt/proximity-example.png',
        title: "",
        text: 'For example we know that the “Favorites” title belongs to the section below due to is proximity to the element.',
        insight: '',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'question',
        question: 'Which of the following best describes the Gestalt principle of Proximity? Elements are perceived as related when they are...',
        image: '',
        imageDesktopLarge: true,
        options: ['visually similar in colour, shape, or size', 'placed close to each other', ' organised into separate regions'],
        correctAnswer: 'placed close to each other',
        explanationImg: '',
        explanation: "",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
      {
        type: 'info',
        image: 'gestalt/similarity.png',
        title: "Similarity Principle",
        text: 'Objects that are similar in appearance are perceived as belonging to the same group.',
        insight: 'Modify the shape of objects to form visual groups, utilising the similarity principle..',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'info',
        image: 'gestalt/similarity-example.png',
        title: "",
        text: 'We will visually separate round and rectangular elements even though they are in the close proximity.',
        insight: '',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'question',
        question: 'Which of the following best describes the Gestalt principle of Similarity? Elements are perceived as related when they are...',
        image: '',
        imageDesktopLarge: true,
        options: ['visually similar in colour, shape, or size', 'placed close to each other', ' organised into separate regions'],
        correctAnswer: 'visually similar in colour, shape, or size',
        explanationImg: '',
        explanation: "",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
      {
        type: 'info',
        image: 'gestalt/similarity.png',
        title: "Common Region",
        text: 'Elements are perceived as grouped together if they are enclosed within a boundary, such as a box, background, or any visible border.',
        insight: 'Group objects by using a shared background or border, following the common region principle.',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'info',
        image: 'gestalt/common-region-example.png',
        title: "",
        text: 'We perceive the flag and the information below as related because they share the same background colour, even though there is a significant distance between them.',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'question',
        question: 'What Gestalt principle is used here to group similar calculator functions?',
        image: 'gestalt/calculator.png',
        imageDesktopLarge: true,
        options: ['Proximity', 'Similarity', 'Common Region'],
        correctAnswer: 'Similarity',
        explanationImg: '',
        explanation: "Colour is used here to clearly distinguish between different type of calculator operations. This is based on the principle of similarity.",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
      {
        type: 'question',
        question: 'What Gestalt principle is used here to visually separate side menu from the channels content?',
        image: 'gestalt/slack.png',
        imageDesktopLarge: true,
        options: ['Proximity', 'Similarity', 'Common Region'],
        correctAnswer: 'Common Region',
        explanationImg: '',
        explanation: "Binding Slack channels with a different coloured container is the application of the Common region principle.",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
      {
        type: 'question',
        question: 'Which two Gestalt principles are applied here to create distinct clusters of settings?',
        image: 'gestalt/ios-settings.png',
        imageDesktopLarge: true,
        options: ['Proximity & Common Region', 'Similarity & Proximity', 'Similarity & Common Region'],
        correctAnswer: 'Proximity & Common Region',
        explanationImg: '',
        explanation: "Related settings options are placed closer together - principle of Proximity. Related settings options are bound by the white background area - principle of Common Region",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
    
    

    {

        type: 'summary',
        keyInsights: ['The 8pt spacing system ensures design uniformity and harmony by using 8-pixel increments for spacing and sizing of elements', 'The 8pt system simplifies the design process by reducing spacing options and enhancing information grouping, making it easier for designers to create clear, organized interfaces.'],
    }
    



]
export default gestaltQuizData