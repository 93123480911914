// PasswordReset.js
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import Modal from './UIComponents/Modal/Modal';
import InputField from './UIComponents/InputField/InputField';
import Button from './UIComponents/Button/Button';
import Banner from './UIComponents/Banner/Banner';

const PasswordReset = ({isOpen, onClose}) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('')


  const handleResetPassword = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'http://localhost:3000/update-password', // optional
    });

    if (error) {
      setMessage('Error: ' + error.message);
      setMessageType('error')
    } else {
      setMessage('Check your email for the password reset link.');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2>Reset Password</h2>
      <p>Enter your email and we’ll send you instructions on how to reset your password.</p>
      {message && <Banner type={messageType} body={message} /> }
      <form onSubmit={handleResetPassword} className='vertical v-m'>
        <InputField
          type="email"
          id="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="primary" text='Send Instructions' fullWidth="true"/>
      </form>
    </Modal>
  );
};

export default PasswordReset;
