import React, { useState } from 'react';
import Modal from './UIComponents/Modal/Modal';
import { supabase, signInWithGoogle  } from '../supabaseClient';
import InputField from './UIComponents/InputField/InputField';
import Button from './UIComponents/Button/Button';
import Banner from './UIComponents/Banner/Banner';

const SignUp = ({isOpen, onClose, openSignIn}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('')

  const handleSignUp = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signUp({ 
      email,
      password,
      options: {
        emailRedirectTo: 'https://betterdesigner.me/'
      }
    });
    if (error) {
      setMessage(error.message);
      setMessageType("warning")
    } else {
      setMessage('Sign up successful. Please check your email to confirm.');
      setMessageType("success")

    }
  };
  const handleGoogleLogin = () => {
    signInWithGoogle();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='vertical v-l'>
        <h4>Sign up</h4>
        <Button onClick={handleGoogleLogin} type='secondary' text='Continue with Google' fullWidth="true" icon="google" size="large" />

        <div className='horizontal h-s center-cross-axis'>
          <hr className='fill border-color'></hr>
          <p>or</p>
          <hr className='fill border-color'></hr>
        </div>

        <form onSubmit={handleSignUp} className='vertical v-m'>
          {message && <Banner type={messageType} body={message} /> }
          <InputField
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <InputField
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="primary" text='Sign-up' fullWidth="true" size="large"/>
        </form>
        <div className='vertical center-cross-axis v-m'>
          <div className='horizontal center-cross-axis h-s'>
            <p>Existing user?</p>
            <a onClick={openSignIn}>Log in</a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignUp;