import React from "react";
import Feedback from "../Feedback/Feedback";
import Tag from "../../UIComponents/Tag/Tag";
import { AcademicCapIcon } from '@heroicons/react/24/outline';
import Image from "../Image/Image";




const QuestionImage = ({ item, index, handleOptionSelect, showFeedback, isCorrect, correctAnswers, selectedOptions }) => {
  // Determine the class name based on the number of options
  const optionsClass = item.options.length === 2 ? 'two-options' : 'more-options';

    return (
        <div className="vertical v-l less-constrained-width questionComponent">
          <div className="vertical v-m ">
            <div className="vertical v-m">
                <Tag type="outline" style="info" icon="purple/dumbbell" text="Practice"/>
                <h6>{item.question}</h6>
            </div>
          
        </div>
         
          <ul className={`image-question-options ${optionsClass}`}>
            {item.options.map((option, optionIndex) => (
              <li
                key={optionIndex}
                onClick={() => handleOptionSelect(option, item.correctAnswer, index)}
                
                className={`option ${
                  showFeedback[index]
                    ? option === correctAnswers[index]
                      ? 'correct'
                      : option === selectedOptions[index] && !isCorrect[index]
                        ? 'incorrect'
                        : 'feedback'
                    : 'no-feedback'
                }`}
              >
                  <Image  image={option} />
              </li>

            ))}
          </ul>
          {showFeedback[index] && (

              <Feedback isCorrect={isCorrect[index]} explanation={item.explanation} />
            
          )}
      </div>
    )
}

export default QuestionImage