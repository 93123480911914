const visualHierachyStrategies = (
    <div className="vertical v-m">
        <p>There are different strategies we can use to establish a visual hierarchy.</p>

        <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>Strategy</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1. Size and scale</td>
              <td>Larger elements appear more important.</td>
            </tr>
            <tr>
              <td>2. Colour and contrast</td>
              <td>Bold colours and high contrast emphasize elements.</td>
            </tr>
            
            <tr>
              <td>3. Whitespace</td>
              <td>Adding space around an element can give it greater emphasis.</td>
            </tr>
            <tr>
            <td>4. Positioning</td>
            <td>Top-left and central elements often hold more importance.</td>
          </tr>
          </tbody>
        </table>
      </div>
    
    </div>
   
  
)

const visualHierarchyQuizData = [
    {
        type: 'intro',
        id: 'visual-hierarchy',
        title: 'Visual Hierarchy',
        duration: '4',
        icon: 'order.svg',
        level: 'Medium',
        description: 'Understand how the principles of visual hierarchy help users quickly identify essential elements on a webpage or app by adjusting element size, contrast, and position.',
    },
    {
        type: 'info',
        image: 'visual-hierarchy/visual-hierarchy.png',
        title: "Intro to Visual Hierarchy",
        text: 'Visual hierarchy is one of the core principles of good design. It refers to the arrangement or presentation of elements in a way that implies importance. By organizing design elements effectively, designers can direct a user’s attention and make information easy to understand.',
        insight: 'Visual hierarchy helps users quickly find what’s important on a webpage or app.',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'question',
        question: 'What is the primary goal of visual hierarchy in UX/UI design?',
        image: '',
        imageDesktopLarge: true,
        options: ['To make the page look aesthetically pleasing', 'To guide users’ attention to the most important content first', ' To make all elements equally noticeable', 'To highlight as many elements as possible'],
        correctAnswer: 'To guide users’ attention to the most important content first',
        explanationImg: '',
        explanation: "Visual hierarchy guides users to quickly identify key information on a webpage or app.",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
      {
        type: 'info',
        title: '',
        image: '',
        htmlContent: visualHierachyStrategies,     
       
      },
      {
        type: 'info',
        image: 'visual-hierarchy/proximity-example.png',
        title: "1. Size and Scale in Visual Hierarchy",
        text: 'One of the simplest ways to establish hierarchy is through size. Larger elements naturally grab more attention than smaller ones. A larger heading or call-to-action (CTA) button signals that it’s more important than smaller text or secondary buttons.',
        insight: 'Bigger elements appear more important and get more attention.',
        imageProductSrc: 'https://n26.com/',
        imageProductLogo: 'visual-hierarchy/n26.png',
        imageProduct: 'N26'
    },
    {
        type: 'question-image',
        question: 'Which of these designs uses size more effectively to establish a more clear visual hierarchy?',
        options: ['visual-hierarchy/vh-size-a.png','visual-hierarchy/vh-size-b.png'],
        correctAnswer: 'visual-hierarchy/vh-size-a.png',
        explanation: "Account balance is probably the most important information on the screen. Therefore, by making it larger than the surrounding elements, we can give it more emphasis and create a more effective visual hierarchy."
    },
    {
        type: 'info',
        image: 'visual-hierarchy/colour-and-contrast.png',
        title: "2. Colour and Contrast",
        text: 'Color can create emphasis and highlight specific elements. Bright, bold colors catch the eye, while dull, muted colors recede into the background. Contrast helps separate different elements and create focal points. See below how N26 uses color to highlight the most important actions.',
        insight: 'High contrast between elements  can direct the user’s attention.',
        imageProductSrc: 'https://n26.com/',
        imageProductLogo: 'visual-hierarchy/n26.png',
        imageProduct: 'N26'
    },
    {
        type: 'question-image',
        question: 'Which of these designs is using colour and  contrast more effectively to establish a clear visual hierarchy?',
        options: ['visual-hierarchy/vh-colour-a.png','visual-hierarchy/vh-colour-b.png'],
        correctAnswer: 'visual-hierarchy/vh-colour-b.png',
        explanation: "By using the primary color—a darker shade of purple—sparingly, we can draw attention to the button"
    },
    {
        type: 'info',
        image: 'visual-hierarchy/vh-white-space.png',
        title: "3. Whitespace",
        text: 'Whitespace (or negative space) refers to the empty spaces between elements in a design. It gives content room to breathe and can also be used to emphasise certain elements by isolating them, making them more prominent.',
        insight: 'More whitespace around an element makes it stand out and gives it greater importance in the hierarchy.',
        imageProductSrc: 'https://apple.com/',
        imageProductLogo: 'visual-hierarchy/apple.png',
        imageProduct: 'Apple'
    },
    {
        type: 'question-image',
        question: 'Which of this designs is using whitespace more effectively to emphasise the current balance?',
        options: ['visual-hierarchy/vh-white-space-a.png','visual-hierarchy/vh-white-space-b.png'],
        correctAnswer: 'visual-hierarchy/vh-white-space-a.png',
        explanation: "By adding extra whitespace around the current balance, we give it greater visual emphasis."
    },
    {
        type: 'info',
        image: 'visual-hierarchy/position-example.png',
        title: "4. Position and Layout in Visual Hierarchy",
        text: 'Elements placed higher on a webpage are typically seen as more important. Users tend to read from top to bottom and left to right (in Western cultures), meaning items placed at the top and left corners usually hold more importance',
        insight: 'Positioning elements strategically (e.g. top of the page) can prioritize their importance in the hierarchy.',
        imageProductSrc: '',
        imageProductLogo: '',
        imageProduct: ''
    },
    {
        type: 'question',
        question: 'Where is the most important element typically placed in a layout to grab the user’s attention?',
        image: '',
        imageDesktopLarge: true,
        options: ['Bottom-right corner', 'Center of the screen', 'Top-left corner', 'Bottom-left corner'],
        correctAnswer: 'Top-left corner',
        explanationImg: '',
        explanation: "",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
      {
        type: 'question',
        question: 'Which of the following is NOT a principle used in establishing visual hierarchy?',
        image: '',
        imageDesktopLarge: true,
        options: ['Size and scale', 'Colour and contrast', 'Typography Style', 'Positioning'],
        correctAnswer: 'Typography Style',
        explanationImg: '',
        explanation: "",
        imageProduct: '',
        imageProductLogo: '',
        imageProductSrc: '',
      },
      {
        type: 'summary',
        keyInsights: [''],
      }
]

export default visualHierarchyQuizData