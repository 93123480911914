import React, { useEffect, useContext, useState } from 'react';
import EmailCapture from './components/EmailCapture';
import LessonCard from './components/QuizComponents/LessonCard/LessonCard';
import Logo from './components/Logo';
import { AuthContext } from './AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import SignInModal from './components/SignInModal';
import SignUp from './components/SignUp';
import RequestResetPassword from './components/RequestResetPassword'
import { supabase } from './supabaseClient';
import { getAllLessonStatuses } from './services/lessonStatusService';
import TopNavBar from './components/UIComponents/TopNavBar/TopNavBar';
import Button from './components/UIComponents/Button/Button';
import { Link } from 'react-router-dom';




const HomePage = ({ introData }) => {

    const { user } = useContext(AuthContext);
    const [lessonStatuses, setLessonStatuses] = useState({});
    const location = useLocation(); // Use location hook to detect route changes
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(null); 

    
    const openSignIn = () => setOpenModal('signIn');
    const openSignUp = () => setOpenModal('signUp');
    const openForgotPassword = () => setOpenModal('forgotPassword');
    const closeModal = () => setOpenModal(null)


    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(user)
    }, []);


    useEffect(() => {
        const fetchStatus = async () => {
            if (user){
                const userId = user.id; // Replace with actual user ID
                const statuses = await getAllLessonStatuses(userId);
                 // Convert the statuses array to an object for easier lookup
                 const statusMap = statuses.reduce((acc, { lesson_id, status }) => {
                    acc[lesson_id] = status;
                    return acc;
                }, {});
                setLessonStatuses(statusMap);
                  
            }
           
        };

        fetchStatus();
    }, [user, location]);

    const handleLessonClick = (lesson) => {
        if (user) {
            // If user is logged in, navigate to the lesson
            navigate(lesson.id);
        } else {
            openSignIn();
        }
    };
    


 

    return (
        <div className="vertical v-xxl m-v-xl">
            <TopNavBar openSignIn={openSignIn} openSignUp={openSignUp} scrollEffect={true} />
        <div>
        <div className='hero horizontal h-xl  center-main-axis'>
            
                <div className='hero-lesson-thumbnails-1 m-hide'>
                    <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-1">
                        <img src={require(`./images/icons/outline/lightbulb.svg`).default} />
                    </div>
                    <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-2">
                        <img src={require(`./images/icons/outline/book-check.svg`).default} />
                    </div>
                    <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-3">
                        <img src={require(`./images/icons/outline/type.svg`).default} />
                    </div>
                    
                </div>
                <div className='hero-inner-text fit-content vertical v-l center-cross-axis center-main-axis'>
                    
                    <div className='vertical v-m hero-lesson-thumbnails-mobile'>
                        <div className='desktop-hide'>
                            <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-1">
                                <img src={require(`./images/icons/outline/lightbulb.svg`).default} />
                            </div>
                            <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-2">
                                <img src={require(`./images/icons/outline/book-check.svg`).default} />
                            </div>
                            <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-3">
                                <img src={require(`./images/icons/outline/type.svg`).default} />
                            </div>
                            <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-4">
                                <img src={require(`./images/icons/outline/dumbbell.svg`).default} />
                            </div>
                        </div>
                        <h1 className='text-center on-surface'>Quiz based design lessons</h1>
                        <p className='sub-title text-center on-surface-variant'>Upgrade your design skills through interactive UX/UI lessons</p>
                    </div>
                </div>
                <div className='hero-lesson-thumbnails-2 m-hide'>
                    <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-1">
                        <img src={require(`./images/icons/outline/pen-tool.svg`).default} />
                    </div>
                    <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-2">
                        <img src={require(`./images/icons/outline/dumbbell.svg`).default} />
                    </div>
                    <div className='hero-lesson-thumbnail-cover' id="hero-thumbnail-3">
                        <img src={require(`./images/icons/outline/paint-bucket.svg`).default} />
                    </div>
                </div>
            
        </div>
       
      </div>
           
            <div className="vertical v-l">
                <div className="lesson-card-container">
                {introData.map((lesson) => (
                    <LessonCard
                        link={`/${lesson.id}`}
                        thumbnailImage={lesson.image}
                        title={lesson.title}
                        icon={lesson.icon}
                        duration={lesson.duration}
                        status={lessonStatuses[lesson.id] || 'not-started'} 
                        onClick={ () => handleLessonClick(lesson)}
                        description={lesson.description}
                        level={lesson.level}
                        key={lesson.title}
                    />
                ))}

                </div>
               
            </div>

          

            <footer className='constrained-width vertical v-m center-main-axis center-cross-axis'>
                <p className='text-center'>Designed and built with coffee and love by <a className='link' href='https://www.linkedin.com/in/maksim-karacun/' target='_blank'>Max</a></p>
                <Link to="/privacy" className='text-center'>
                    Privacy policy
                </Link>
                
            </footer>

            {/* Conditionally render modals based on the `openModal` state */}
            {openModal === 'signIn' && (
                <SignInModal onClose={closeModal} openSignUp={openSignUp} isOpen={openSignIn} openForgotPassword={openForgotPassword} />
            )}
            {openModal === 'signUp' && (
                <SignUp onClose={closeModal} openSignIn={openSignIn} isOpen={openSignUp}  />
            )}
            {openModal === 'forgotPassword' && (
                <RequestResetPassword onClose={closeModal} isOpen={openForgotPassword}  />
            )}
        </div>
    )
}

export default HomePage;