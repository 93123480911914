import React from "react";

const ProgressBar = ({currentQuestionIndex, quizDataLength}) => {

    const progressValue = ((currentQuestionIndex + 1) / quizDataLength ) * 100
    return (
        <div className="full-bar">
            <div className="progress-bar" style={{ width: progressValue + '%' }}>
            </div>
        </div>
    )
}

export default ProgressBar