import React, { useContext, useState, useEffect } from "react";
import Button from "../Button/Button";
import Logo from "../../Logo";
import { Link } from "react-router-dom";
import { AuthContext } from '../../../AuthContext';
import Avatar from "../Avatar/Avatar";

const TopNavBar = ({ openSignIn, openSignUp, scrollEffect }) => {
    const { user } = useContext(AuthContext);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (scrollEffect) {
            const handleScroll = () => {
                const offset = window.scrollY;
                if (offset > 0) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            };

            window.addEventListener('scroll', handleScroll);

            // Cleanup the event listener on component unmount
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollEffect]);

    return (
        <header
            className={`fill horizontal center-cross-axis space-between ${
                scrolled ? 'navbar-scrolled' : 'navbar-top'
            } ${scrollEffect ? '' : 'navbar-scrolled' }`}
        >
            <Logo />
            <div>
                {user ? (
                    <div className="horizontal h-m">
                        <Avatar />
                    </div>
                ) : (
                    <div className="horizontal h-m">
                        <Button text="Log-in" type="secondary" onClick={openSignIn} />
                        <Button text="Sign-up" type="primary" onClick={openSignUp} />
                    </div>
                )}
            </div>
        </header>
    );
};

export default TopNavBar;
