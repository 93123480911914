import React from "react";
import Feedback from "../Feedback/Feedback";
import Tag from "../../UIComponents/Tag/Tag";
import Option from "../Option/Option";
import styles from './Question.module.css';
import Image from '../Image/Image';


const Question = ({ item, index, handleOptionSelect, showFeedback, isCorrect, selectedOptions }) => {
    return (
        <div className={`vertical v-l less-constrained-width ${styles.questionComponent}`}>
          
          <div className="vertical v-m">
            <Tag type="outline" style="info" icon="purple/dumbbell" text="Practice"/>
            <h6>{item.question}</h6>
          </div>
        
          <div className={`h-l mobile-vertical v-m mobile-start-cross-axis fill ${item.imageDesktopLarge ? 'vertical imageDesktopLarge' : 'horizontal'}`}>
            
            {item.image && (
              
               <Image
                image={item.image}
                imageProductSrc={item.imageProductSrc}
                imageProductLogo={item.imageProductLogo}
                imageProduct={item.imageProduct}
               />
              
            )}
            
            <ul className={`fill vertical v-s ${styles.questionOptions}`}>
              {item.options.map((option, optionIndex) => (
                <Option 
                  key={optionIndex} 
                  optionIndex={optionIndex} 
                  option={option}
                  handleOptionSelect={handleOptionSelect} 
                  showFeedback={showFeedback} 
                  index={index}
                  correctAnswer={item.correctAnswer}
                  selectedOptions={selectedOptions}
                  isCorrect={isCorrect}
                >
                  {option}
                </Option>
              ))}
            </ul>
          </div>
       
        {showFeedback[index] && (
            <Feedback 
              isCorrect={isCorrect[index]} 
              explanation={item.explanation} 
              explanationImg={item.explanationImg} 
              explanationImgSrc={item.explanationImgSrc} 
              explanationImgProduct={item.explanationImgProduct} 
              explanationImgProductLogo={item.explanationImgProductLogo} 
            />
        )}
      </div>
    )
}

export default Question