import React from "react";
import Tag from "../../../components/UIComponents/Tag/Tag"

const LessonCard = ({title, duration, link, status, onClick, icon, description, level}) => {
    
    const truncate = (str, maxLength) => {
        return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
    };

    return(
        <div className="vertical v-xl border-variant background-surface-container-low h-padding-m v-padding-m lesson-card"  onClick={onClick}>
            <div className="vertical v-s">
                <div className="horizontal space-between">
                    <div className="vertical background-surface-container border-variant center-main-axis center-cross-axis lesson-card-icon-container-sm">
                        {icon && <img src={require(`../../../images/icons/${icon}`)} />}
                    </div>
                    {status==="completed" ? <Tag type={"outline"} style={"success"} text={"Completed"} icon={"green/check"}/>  : "" }
                </div>
                  
                <h5 className="on-surface">{title}</h5>
                <p className="description on-surface-variant">{truncate(description, 120)}</p>
            </div>
            <ul className="vertical v-s">
                <li className="horizontal center-cross-axis h-xs dashed-top-border">
                    <img className="size-m" src={require(`../../../images/icons/lucide_clock.svg`).default} />
                    <p className="on-surface">{duration + " min"}</p>
                </li>
                <li className="horizontal center-cross-axis h-xs dashed-top-border">
                    <img className="size-m" src={require(`../../../images/icons/lucide_dumbbell.svg`).default} />
                    <p className="on-surface">{level}</p>
                </li>

            </ul>

        </div>
    )
}

export default LessonCard;