import React from "react";
import styles from "./MenuItem.module.css"
import PropTypes from 'prop-types';


const MenuItem = ({text, icon=null, onClick}) => {

    return(
        <div className={`${styles.menuItem} horizontal h-s`} onClick={onClick}>
            {icon && <img src={require(`../../../images/icons/${icon}.svg`)} alt="" className={styles.icon} />}
            {text}
        </div>
    )
}

MenuItem.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func, // Make onClick optional
};

export default MenuItem