const progressiveDiscTypes = (
    <div className="vertical v-m">
        <p>There are different types of progressive disclosure.</p>

        <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>UI Pattern</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sequential disclosure</td>
              <td>Breaking complex tasks into manageable steps. Each step is revealed after the previous one is completed.</td>
              <td>Multi-step flow</td>
            </tr>
            <tr>
              <td>Conditional disclosure</td>
              <td>Hiding certain elements until the user needs them, based on their actions or input.</td>
              <td>Dynamic forms, displaying extra fields based on previous selections</td>
            </tr>
            
            <tr>
              <td>Contextual disclosure</td>
              <td>Offering additional information without leaving the current context.</td>
              <td>Tooltips, Modals, Popovers</td>
            </tr>
          </tbody>
        </table>
      </div>
    
    </div>
   
  
)
const progressiveDiscTypesMeaning = [

  { id: 3, item: 'Contextual disclosure', img:'', pairId: 2, column: 1 },
  { id: 4, item: 'Offering supplementary information or actions that enhance the current user task.',img: '', pairId: 2, column: 2 },
  { id: 5, item: 'Conditional disclosure',  pairId: 3, img:'', column: 1 },
  { id: 6, item: 'Showing additional fields based on user choices.', img: '', pairId: 3, column: 2 },
  { id: 7, item: 'Sequential disclosure',  pairId: 4, img:'', column: 1 },
  { id: 8, item: 'Presenting fields in a sequence to keep users focused on completing one section at a time.', img: '', pairId: 4, column: 2 }
];

const progressiveDiscQuizData = [


    {
      type: 'intro',
      image: 'progressive-disc-cover.png',
      id: 'progressive-disc',
      title: 'Progressive Disclosure',
      duration: '3',
      icon: 'eye.svg',
      level: 'Advanced',
      description: 'Understand progressive disclosure and its power in user interface design.',
      keyInsights: ['Define progressive disclosure and explain its purpose in user interface design.', 'Describe the four main types of progressive disclosure: Sequential, Conditional, Hierarchical, and Contextual.','Provide examples of user interface patterns for each type of progressive disclosure.'],
    },

    {
        type: 'info',
        title: 'Intro',
        image: 'progressive-disc/progressive-disc-ux.png',
        text: 'UX Designers face a dilemma on one side we want to deliver a lot of features to our users and give them power, on the other, we want to make our interface as simple as possible. Progressive disclosure is one of the best ways to satisfy both of these conflicting requirements.',
    },
    {
        type: 'info',
        title: 'What is a progressive disclosure?',
        image: 'progressive-disc/progressive-disc-klarna.png',
        text: 'Progressive disclosure is a design technique that presents information and options gradually, preventing users from being overwhelmed by too much content at once. By revealing information step-by-step or based on user actions, it enhances usability and ensures a smoother user experience.',
        imageProduct: 'Klarna',
        imageProductSrc: 'https://www.klarna.com/',
        imageProductLogo: 'progressive-disc/klarna-logo.png',
    },
    {
        type: 'question',
        question: 'What is NOT the benefit of progressive disclosure?',
        image: '',
        options: ['Simplify the user interface', 'Reduce the cognitive load', 'Increase the efficiency of accessing information','Reduce the learning curve' ],
        correctAnswer: 'Increase the efficiency of accessing information',
        explanationImg: '',
        explanation: "A potential drawback of progressive disclosure is that it can sometimes frustrate experienced users who prefer to access all information and options quickly without going through multiple steps or interactions. This technique may slow down their workflow by requiring additional clicks or interactions to reveal the information they need.",

      },
      {
        type: 'info',
        title: 'Progressive Disclosure Types',
        image: '',
        htmlContent: progressiveDiscTypes,     
       
      },
      {
        type: 'info',
        title: 'Sequential Disclosure',
        text: 'Sequential disclosure involves dividing complex tasks into smaller, manageable steps, revealing each step in order upon completion of the previous one. This is pattern is commonly used for the onboarding flows.',
        image: 'progressive-disc/progressive-disc-babel.png',
        imageProduct: 'Babbel',
        imageProductLogo: 'progressive-disc/babel-logo.png',
        imageProductSrc: 'https://www.babbel.com/',
        insight: "Break complex tasks into manageable steps. Reveal each step after the previous one is completed."

      },
      {
        type: 'info',
        title: 'Conditional Disclosure',
        text: 'Conditional disclosure involves revealing elements only when necessary based on user actions or inputs. Thereby, reducing the complexity of the screen.',
        image: 'progressive-disc/progressive-disc-conditional-disclosure.png',
        insight: "Hide certain elements until the user needs them."

      },

      {
        type: 'question',
        question: 'Which type of disclosure is used in this onboarding flow?',
        image: 'progressive-disc/opal-disclosure.png',
        imageDesktopLarge: true,
        options: ['Hierarchical', 'Sequential', 'Contextual', 'Conditional' ],
        imageProduct: 'Opal',
        imageProductLogo: 'progressive-disc/opal-logo.png',
        imageProductSrc: 'https://apps.apple.com/us/app/opal-screen-time-for-focus/id1497465230',
        correctAnswer: 'Sequential',
        explanationImg: '',
        explanation: "Breaking onboarding questions into steps is an example of a sequential disclosure.",
      },
      {
        type: 'info',
        title: 'Contextual Disclosure',
        text: 'Contextual disclosure involves providing additional information or options directly within the current context.',
        image: 'progressive-disc/contextual-disclosure.png',
        insight: "Offer additional information without leaving the current context using tooltips, modals and popovers."

      },
      {
        type: 'question-matching-pairs',
        pairs: progressiveDiscTypesMeaning,
        explanation: ''
      },
      {
        type: 'question',
        question: 'Which type of disclosure is used here?',
        image: 'progressive-disc/progressive-disc-shop.png',
        options: ['Conditional', 'Sequential', 'Hierarchical','Contextual' ],
        correctAnswer: 'Contextual',
        explanationImg: '',
        explanation: "Providing additional information using tooltips is an example of contextual disclosure.",
        imageProduct: 'Shop',
        imageProductLogo: 'progressive-disc/shop-logo.png',
        imageProductSrc: 'https://shop.app/',
      },


      {
        type: 'summary',
        keyInsights: ['Progressive disclosure allows UX designers to deliver feature-rich interfaces while maintaining simplicity by gradually revealing information and options, thus preventing user overwhelm.', 'There are four main types of progressive disclosure: Sequential (step-by-step tasks), Conditional (elements revealed based on user actions), Hierarchical (layered information access), and Contextual (in-context information).', 'Understanding and identifying the appropriate type of progressive disclosure for different scenarios can significantly enhance usability, making interfaces more intuitive and efficient for users of varying experience levels.'],
      }
]  

export default progressiveDiscQuizData