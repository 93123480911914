// src/EmailCaptureForm.js
import React, { useState } from 'react';
import axios from 'axios';

const EmailCaptureForm = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [statusClass, setStatusClass] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    setStatusClass(''); // Clear any previous status class
    try {
      const response = await axios.post('/.netlify/functions/express/subscribe', { email });
      if (response.data.error) {
        setStatus('Error: ' + response.data.error.message);
        setStatusClass('error-status');
      } else {
        setStatus('Success! You have been subscribed.');
        setStatusClass('success-status');
      }
    } catch (error) {
      setStatus('Error: ' + error.message);
      setStatusClass('error-status');
    }
  };

  return (
    <div className='vertical'>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="email"
            placeholder='example@gmail.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <button className='m-small-button' type="submit">Subscribe</button>
      </form>
      <div id='status-message' className={statusClass}>{status}</div>
    </div>
  );
};

export default EmailCaptureForm;
