import React from 'react';
import styles from './Banner.module.css'; // Your CSS module

// Import your icons from the folder
import { ReactComponent as WarningIcon } from '../../../images/icons/warning-filled-red.svg';
import { ReactComponent as InfoIcon } from '../../../images/icons/info-filled.svg';
import { ReactComponent as InsightIcon} from '../../../images/icons/lucide_lightbulb.svg';
import {ReactComponent as SuccessRoundIcon} from '../../../images/icons/circle-check-filled-green.svg';

// Create an icon map to link icon names to actual components
const iconMap = {
  warning: WarningIcon,
  info: InfoIcon,
  // Add more icons here as needed
};

const Banner = ({ type = 'default', icon: iconName, title, body }) => {
  // Dynamically get the icon component from the map
  const IconComponent = iconMap[iconName];

  const getIcon = () => {
    switch (type) {
      case 'warning':
        return <WarningIcon className={styles.warningIcon} />; // Default icon for warning type

      case 'success':
        return <SuccessRoundIcon />
      case 'insight':
        return <InsightIcon />
      default:
        // Use the icon component from the iconMap if the icon name is valid
        return IconComponent ? <IconComponent className={styles.icon} /> : null;
    }
  };

  return (
    <div className={`${styles.banner} ${styles[type]} horizontal h-s h-padding-s v-padding-s`}>
      <div className={styles.iconContainer}>
        {getIcon()}
      </div>
      <div className="vertical v-s">
        {title && <h5>{title}</h5>}
        <p>{body}</p>
      </div>
    </div>
  );
};

export default Banner;
