import React from "react";
import celebrationSound from '../audio/celebration-sound.mp3';
import UserFeedback from "./UserFeedback";
import Banner from "./UIComponents/Banner/Banner";


const Summary = ({insights, lessonName}) => {
    const celebration = new Audio(celebrationSound);
    celebration.play();

    return (
        <div className="vertical v-xl  mobile-v-padding-m v-padding-xl  border-radius grey-surface constrained-width">
            <div className="vertical v-s ">


                <h4>Lesson Completed!</h4>
            </div>
            <div className="vertical v-m">
                <p>Key insights from this lesson:</p>
                <ul className="vertical v-m">
                    {insights.map((insight ,index) => (
                       
                        <li key={index}>
                            <Banner type="insight" key={index} body={insight}/>
                        </li>
                       
                    ))}
                </ul>
            </div>

            <UserFeedback lessonName={lessonName} />
          


        </div>
    )
}

export default Summary