import { supabase } from '../supabaseClient';

export const getAllLessonStatuses = async (userId) => {
    const { data, error } = await supabase
        .from('user_lesson_statuses')
        .select('lesson_id, status')
        .eq('user_id', userId);

    if (error) {
        console.error('Error fetching all lesson statuses:', error);
        return [];
    }

    return data; // Return an array of { lesson_id, status }
};

export const setLessonStatus = async (userId, lessonId, status) => {
    const { data, error } = await supabase
        .from('user_lesson_statuses')
        .upsert([{ lesson_id: lessonId, user_id: userId, status: status}])
  
    if (error) {
      console.error('Error setting lesson status:', error);
      return null;
    }
  
    return data;
};
  
  