const eightptsystemQuizData = [
   
    {
        type: 'intro',
        id: 'eight-pt-system',
        image: 'space-cover.png',
        title: '8pt System',
        duration: '3',
        icon: 'ruler.svg',
        level: 'Beginner',
        description: 'Understand the concept of the 8pt spacing system and its numerous benefits.',
        keyInsights: ['Understand the concept of the 8pt spacing system', 'Analyse and improve designs using the 8pt system','Understand the benefits of the 8pt spacing system'],
      },
      {
        type: 'info',
        image: '8pt-system/8pt-system.png',
        title: "Intro",
        text: 'The 8pt spacing system is a design framework that uses increments of 8 pixels for spacing, sizing, and aligning elements in a user interface. This system ensures consistency and harmony, simplifying the design and development process.',
      },

      {
        type: 'question',
        question: 'Which of these follows an 8pt system scale?',
        image: '',
        options: ['8, 10, 16, 24…', '8, 16, 24, 32…', '4, 8, 12, 18…', '8, 18, 28, 38…'],
        correctAnswer: '8, 16, 24, 32…',
        explanationImg: '',
        explanation: ""
      },
      {
        type: 'question-image',
        question: 'Which of these designs follows an 8pt system?',
        options: ['8pt-system/8pt-system-option-a.png','8pt-system/8pt-system-option-b.png'],
        correctAnswer: '8pt-system/8pt-system-option-a.png',
        explanation: "All of the spaces used should be a multiple of 8 e.g. 8, 16, 24, 32..."
    },

    {
        type: 'info',
        image: '8pt-system/8pt-system-information-grouping.png',
        title: 'Information Grouping',
        text: 'The 8pt system helps create clear information grouping by ensuring consistent spacing between related elements, making it easier for users to distinguish between different sections and categories.',
        insight: "8pt spacing system helps to enhance information grouping."
    },
    {
        type: 'info',
        title: "Simplifies Decision Making",
        image: '8pt-system/8pt-system-efficiency.png',
        text: 'Another benefit of the 8pt system is that it simplifies the decision-making process, as you typically only need to choose between 2 options compared to many options.',
        insight: "8pt spacing system helps to make decisions more efficiently."

    },
    {
        type: 'question',
        question: 'Which of these is NOT a benefit of the 8pt system?',
        image: '',
        options: ['Ensures a consistent and cohesive spacing system.', 'Helps to establish clear information grouping.', 'Simplifies the design decision-making process.', 'Creates more varied spacing between elements.'],
        correctAnswer: 'Creates more varied spacing between elements.',
        explanationImg: '',
        explanation: "On the contrary, 8pt system creates less varied spacing between elements."
      },
      {
        type: 'question',
        question: 'According to the 8pt system, what would make the most sense for this spacing?',
        image: '8pt-system/8pt-system-spacing-guess.png',
        options: ['8','14','16', '24'],
        correctAnswer: '16',
        explanationImg: '8pt-system/8pt-system-spacing-answer.png',
        explanation: "Following, 8pt system, 16px would be the right spacing interval."
      },
      {
        type: 'info',
        image: '8pt-system/8pt-system-sizing-elements.png',
        title: 'Sizing Elements',
        text: 'The 8pt system can be used beyond just spacing; it can also be applied to element sizing, typography scale, border radius, etc.',
        insight: 'Use 8pt system beyond spacing e.g. element sizing, typography etc. '
    },
    {
        type: 'question',
        question: 'Which of the following design elements can the 8pt system be applied to, besides spacing?',
        image: '',
        options: ['Element sizing', ' Typography scale', 'Border radius', 'All of the above'],
        correctAnswer: 'All of the above',
        explanationImg: '',
        explanation: "The 8pt system extends beyond mere spacing; it can also be applied to element sizing, typography scales, border radii, and more."
      },
    {
        type: 'summary'
    }
    
]

export default eightptsystemQuizData