import React, { useState } from 'react';
import Button from '../components/UIComponents/Button/Button'

function UserFeedback({lessonName}) {
  const [formData, setFormData] = useState({
    message: '',
    rating: '',
    lessonName: lessonName
  });
  const [status, setStatus] = useState('');
  const [statusClass, setStatusClass] = useState('');
  const [formVisible, setFormVisible] = useState(true);
  const [additionalFeedbackVisible, setAdditionalFeedbackVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'rating') {
        setAdditionalFeedbackVisible(true);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    const response = await fetch('https://script.google.com/macros/s/AKfycbyupGoftRfJz31MBBGV5RjR4Qxpj_OHCARa8WTdDzxrfav0c6zlB_shO2LAsKBDwyfA/exec', {
      method: 'POST',
      body: new URLSearchParams(formData)
    });

    if (response.ok) {
        setStatus('Success! Your feedback has been sent.');
        setStatusClass('success-status');
        setFormVisible(false);
    } else {
        setStatus('Error: ' + response.data.error.message);
        setStatusClass('error-status');
    }

    setFormData({ message: '', rating: '' });
    setIsLoading(false); // Stop loading
  };

  return (
    <div>
      {formVisible ? (
        <form onSubmit={handleSubmit} className='user-feedback-form vertical v-xl start-cross-axis'>
          
          <div className='user-feedback vertical v-m fill'>
            <h6>How would you rate this lesson?</h6>
            <div className='horizontal h-s'>
              <label onChange={handleChange} className={`option ${formData.rating === "very-bad" ? "selected" : ""}`}>
                <h4>😖</h4>
                <p>Very bad</p>
                <input type="radio" name="rating" value={"very-bad"} checked={formData.rating === String("very-bad")} required />
              </label>
              <label onChange={handleChange} className={`option ${formData.rating === "bad" ? "selected" : ""}`}>
                <h4>🙁</h4>
                <p>Bad</p>
                <input type="radio" name="rating" value={"bad"} checked={formData.rating === String("bad")} required />
              </label>
        <label onChange={handleChange} className={`option ${formData.rating === "okay" ? "selected" : ""}`}>
            <h4>😐</h4>
            <p>Okay</p>
            <input type="radio" name="rating" value={"okay"} checked={formData.rating === String("okay")} required />
        </label>
        <label onChange={handleChange} className={`option ${formData.rating === "good" ? "selected" : ""}`}>
            <h4>😊</h4>
            <p>Good</p>
            <input type="radio" name="rating" value={"good"} checked={formData.rating === String("good")} required />
        </label>
        <label onChange={handleChange} className={`option ${formData.rating === "excellent" ? "selected" : ""}`}>
            <h4>😍</h4>
            <p>Excellent</p>
            <input type="radio" name="rating" value={"excellent"} checked={formData.rating === String("excellent")} required />
        </label>
            </div>
          </div>
          {additionalFeedbackVisible ? (
            <div className='additional-feedback vertical v-l fill'>
                <label className='vertical v-m'>
                    <h6>Why did you give this rating? <span className='secondary-text'>(optional)</span></h6>
                    <textarea name="message" value={formData.message} onChange={handleChange} className='fill' placeholder='Give a short description' />
                </label>
            <button type="submit" className='button secondary'> {isLoading ? <span className="loader"></span> : 'Submit feedback'}</button>
            
          </div>
          ) : null }
          
          
        </form>
      ) : null}
      <div id='status-message' className={statusClass}>{status}</div>
    </div>
  );
}

export default UserFeedback;
