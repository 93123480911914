import React from "react";
import Image from "../Image/Image";
import Banner from "../../UIComponents/Banner/Banner";

const Info = ({item}) => {
    return(
        <div className="vertical v-xl">
            <div className="vertical v-l">
                 <div className="vertical v-s">
                    <h6>{item.title}</h6>
                    <p>{item.text}</p>
                </div>
               
                {item.image && (
                    <Image
                        image={item.image}
                        imageProductSrc={item.imageProductSrc}
                        imageProductLogo={item.imageProductLogo}
                        imageProduct={item.imageProduct}
                    />
                )}
                {item.htmlContent && (item.htmlContent)}
                {item.insight && <Banner type="insight" body={item.insight}/> }
               
               
                
            </div>
        </div>
    )
}
export default Info
