import React from "react";
import styles from "./Tag.module.css"

const Tag = ({type, style, icon, text}) => {

   
    return(
        <div className={`${styles[type]} ${styles[style]} ${styles.tag}`}>
            {icon && <img className={`${styles.icon}`} src={require(`../../../images/icons/${icon}.svg`)} />}
            <p>{text}</p>
        </div>
    )
}
export default Tag